<template>
  <div class="app-container">
    <div style="text-align: right; width: 100%;">
      <el-tag v-if="!canAssignTicketsToEmployee" type="danger" effect="plain">{{
        $t('employeeWorklog.fillInCompleteForm')
      }}</el-tag>
      <editor-button
        :icon-only="false"
        :loading="creatingWorklogs"
        button-translation="employeeWorklog.save"
        action="primary"
        :disabled="!canAssignTicketsToEmployee"
        @click="saveWorklogs"
      />
    </div>
    <div style="width: 100%;">
      <el-row :gutter="100">
        <el-col :span="12">
          <el-form ref="form" :model="form" label-width="140px">
            <el-form-item :label="$t('employeeWorklog.employee')">
              <el-select
                v-model="selectedEmployee"
                :clearable="true"
                class="search-select"
                type="select"
                style="margin-bottom: 10px"
                :placeholder="$t('employeeWorklog.employee')"
                @input="handleEmployeeChange($event)"
              >
                <el-option
                  v-for="employee in employees"
                  :key="employee.id"
                  :label="employee.name"
                  :value="employee.id"
                />
              </el-select>
            </el-form-item>
            <el-form-item :label="$t('employeeWorklog.workedAt')">
              <el-date-picker
                v-model="selectedDate"
                type="datetime"
                style="margin-left: 4px"
                :format="'dd-MM-yyyy'"
                :placeholder="$t('employeeWorklog.workedAt')"
                name="date"
                :picker-options="pickerOptions"
                @input="handleWorkedAtChange($event)"
              />
            </el-form-item>
            <el-form-item :label="$t('employeeWorklog.remarks')">
              <el-input v-model="form.remarks"></el-input>
            </el-form-item>

            <el-form-item :label="$t('employeeWorklog.startedAt')">
              <el-time-select
                v-model="form.startedAt"
                :picker-options="{
                  start: '08:30',
                  step: '00:15',
                  end: '23:45'
                }"
              ></el-time-select>
            </el-form-item>

            <el-form-item :label="$t('employeeWorklog.endsAt')">
              <el-time-select
                v-model="form.endsAt"
                :picker-options="{
                  start: '08:30',
                  step: '00:15',
                  end: '23:45'
                }"
              ></el-time-select>
            </el-form-item>

            <el-form-item :label="$t('employeeWorklog.breakDuration')">
              <md-input v-model="form.breakDurationHours" :placeholder="$t('employeeWorklog.hours')"></md-input>
              <md-input v-model="form.breakDurationMinutes" :placeholder="$t('employeeWorklog.minutes')"></md-input>
            </el-form-item>

            <el-form-item :label="$t('employeeWorklog.scanTicket')">
              <el-input v-model="form.ticketNumber" @keyup.enter.native="onTicketNumberEntered"></el-input>
            </el-form-item>
          </el-form>
        </el-col>
        <el-col :span="12">
          <el-table :data="summary" style="width: 100%">
            <el-table-column prop="label" width="180"></el-table-column>
            <el-table-column prop="value" width="180"></el-table-column>
          </el-table>
        </el-col>
      </el-row>
      <div ref="tableContainer">
        <el-table
          ref="unassignedTicketsList"
          :data="filteredItems"
          border
          row-key="orderID"
          fit
          :max-height="getMaxTableHeight()"
          @selection-change="handleSelectionChange"
        >
          <el-table-column :label="$t('employeeWorklog.ticketNumber')" prop="ticketNumber" width="200px">
            <template slot-scope="scope">
              <span>{{ scope.row['ticketNumber'] }}</span>
            </template>
          </el-table-column>

          <el-table-column :label="$t('employeeWorklog.paymentMethod')" prop="paymentMethod" width="250">
            <template slot-scope="scope">
              <el-select
                v-model="scope.row['paymentMethod']"
                type="select"
                @input="updatePaymentMethod(scope.row, $event)"
              >
                <el-option
                  v-for="paymentMethod in paymentMethods"
                  :key="paymentMethod.code"
                  :label="paymentMethod.description"
                  :value="paymentMethod.code"
                />
              </el-select>
            </template>
          </el-table-column>

          <el-table-column :label="$t('employeeWorklog.amount')" prop="paidAmount" width="250">
            <template slot-scope="scope">
              <md-input
                v-model="scope.row['paidAmount']"
                type="currency"
                :readonly="!canChangePaidAmount(scope.row)"
                @input="updatePaidAmount(scope.row, $event)"
              ></md-input>
            </template>
          </el-table-column>

          <el-table-column :label="$t('employeeWorklog.employee')">
            <template slot-scope="scope">
              <el-button
                v-if="!isIncluded(scope.row)"
                type="text"
                size="small"
                @click.native.prevent="includeRow(scope.row)"
              >
                {{ $t('employeeWorklog.isExcluded') }}
              </el-button>
              <el-button
                v-if="isIncluded(scope.row)"
                type="text"
                size="small"
                @click.native.prevent="excludeRow(scope.row)"
              >
                {{ $t('employeeWorklog.isIncluded', { name: employeeName }) }}
              </el-button>
            </template>
          </el-table-column>
          <el-table-column
            fixed="right"
            type="selection"
            :reserve-selection="true"
            width="60"
            align="center"
            :selectable="() => true"
          >
          </el-table-column>
        </el-table>
      </div>
      <div style="margin-top: 10px;">
        <label class="selectionFooterLabel">{{ $t('employeeWorklog.totalAmount') }}: </label>
        <md-input v-model="totalAmount" type="currency" :readonly="true"></md-input>
      </div>
    </div>
  </div>
</template>

<script>
import EditorButton from '@/components/crud/EditorButton';
import { toString } from '@/utils/date';
import { uniqueArr } from '@/utils';
import MdInput from '../../components/MDinput';
import { PaymentMethodOption } from '../../utils/constants';

export default {
  name: 'EmployeeWorklog',
  components: {
    MdInput,
    EditorButton
  },
  data() {
    return {
      loading: false,
      loadedData: { items: [], total: 0 },
      filteredItems: [],
      scannedOrders: [],
      errorMsg: '',
      employeeID: null,
      costs: null,
      change: null,
      workedAt: null,
      multipleSelection: [],
      included: [],
      totalAmount: null,
      creatingWorklogs: false,
      refreshSummaryTrigger: 0,
      form: {
        remarks: '',
        startedAt: null,
        endsAt: null,
        breakDurationHours: null,
        breakDurationMinutes: null,
        ticketNumber: null
      },
      paymentMethods: []
    };
  },
  computed: {
    listParams() {
      return {
        employeeID: this.selectedEmployee || null,
        workedAt: toString(this.selectedDate, 'YYYY-MM-DD')
      };
    },
    items() {
      return this.loadedData.items;
    },
    selectedEmployee: {
      get() {
        return this.employeeID;
      },
      set(newEmployee) {
        return (this.employeeID = newEmployee);
      }
    },
    selectedDate: {
      get() {
        return this.workedAt;
      },
      set(newDate) {
        return (this.workedAt = newDate);
      }
    },
    employees() {
      return this.$store.getters['employee/loadedItems'].filter(e => e.onlineYN);
    },
    employeeName() {
      const employee = this.$store.getters['employee/getLoadedItemByID'](this.selectedEmployee);
      return employee && employee.name;
    },
    canAssignTicketsToEmployee() {
      return this.selectedEmployee && this.form.startedAt && this.form.endsAt;
    },
    pickerOptions() {
      const numberOfDays = this.$store.getters['configuration/value']('employeeWorklog.entryPossibleInDaysInThePast');
      return {
        disabledDate(givenDate) {
          const lastDate = new Date();
          const firstDate = new Date();
          firstDate.setDate(firstDate.getDate() - numberOfDays);
          const date = new Date(givenDate);
          return date < firstDate || date > lastDate;
        },
        firstDayOfWeek: 1
      };
    },
    includedOrders() {
      return this.filteredItems.filter(e => this.included.some(id => id === e.orderID));
    },
    totalOrderAmountForDude() {
      let totalAmount = 0;
      this.includedOrders.forEach(o => (totalAmount += o.amount));
      return totalAmount;
    },
    totalElectronicallyPaidAmountForDude() {
      let totalAmount = 0;
      this.includedOrders.filter(o => this.isElectronicallyPaid(o)).forEach(o => (totalAmount += o.paidAmount));
      return totalAmount;
    },
    totalCashPaidAmountForDude() {
      let totalAmount = 0;
      this.includedOrders.filter(o => this.isCash(o)).forEach(o => (totalAmount += o.paidAmount));
      return totalAmount;
    },
    totalGiftCardAmountForDude() {
      let totalAmount = 0;
      this.includedOrders.filter(o => this.isGiftCard(o)).forEach(o => (totalAmount += o.paidAmount));
      return totalAmount;
    },
    totalInvoiceAmountForDude() {
      let totalAmount = 0;
      this.includedOrders.filter(o => this.isInvoice(o)).forEach(o => (totalAmount += o.paidAmount));
      return totalAmount;
    },
    summary() {
      const electronicallyPayed = this.totalElectronicallyPaidAmountForDude;
      const cashPayedAmount = this.totalCashPaidAmountForDude;
      const totalOrderAmount = this.totalOrderAmountForDude;
      const totalInvoiceAmount = this.totalInvoiceAmountForDude;
      const totalGiftCardAmount = this.totalGiftCardAmountForDude;
      const amountToBeSettled =
        cashPayedAmount +
        (this.costs || 0) +
        (this.change || 0) +
        this.refreshSummaryTrigger -
        this.refreshSummaryTrigger;
      return [
        { label: this.$i18n.t('employeeWorklog.numberOfTickets'), value: this.includedOrders.length },
        {
          label: this.$i18n.t('employeeWorklog.totalTicketAmount'),
          value: this.formatAmount(totalOrderAmount)
        },
        {
          label: this.$i18n.t('employeeWorklog.totalElectronicAmount'),
          value: this.formatAmount(electronicallyPayed, '-')
        },
        {
          label: this.$i18n.t('employeeWorklog.totalInvoiceAmount'),
          value: this.formatAmount(totalInvoiceAmount, '-')
        },
        {
          label: this.$i18n.t('employeeWorklog.totalGiftcardAmount'),
          value: this.formatAmount(totalGiftCardAmount, '-')
        },
        { label: this.$i18n.t('employee.cost'), value: this.formatAmount(this.costs) },
        { label: this.$i18n.t('employee.change'), value: this.formatAmount(this.change) },
        { label: this.$i18n.t('employeeWorklog.amountToBeSettled'), value: this.formatAmount(amountToBeSettled) }
      ];
    }
  },
  created() {
    this.$store.dispatch('employee/getItems');
    this.paymentMethods = this.$store.getters['option/loadedOptions']('paymentMethod');
  },
  methods: {
    handleSelectionChange(val) {
      this.multipleSelection = val;
      const notExcluded = this.items
        .filter(e => !this.multipleSelection.some(selected => selected.orderID === e.orderID))
        .map(ms => ms.orderID);

      const excluded = this.items
        .filter(e => this.multipleSelection.some(selected => selected.orderID === e.orderID))
        .map(ms => ms.orderID);

      this.included = this.included.filter(excludedID => !notExcluded.some(selectedID => selectedID === excludedID));
      this.included = uniqueArr(this.included.concat(excluded));
    },
    updatePaidAmount(row, amount) {
      this.refreshSummary();
    },
    updatePaymentMethod(row, paymentMethod) {
      const changePaymentMethodCommand = {
        orderID: row.orderID,
        paymentMethod: paymentMethod
      };
      this.$store
        .dispatch('employeeWorklog/changePaymentMethod', changePaymentMethodCommand)
        .then(result => {
          this.$store.dispatch('notify/success', {
            message: this.$i18n.t('employeeWorklog.changePaymentMethodSuccess')
          });
        })
        .catch(() => {
          this.$store.dispatch('notify/error', { message: this.$i18n.t('employeeWorklog.changePaymentMethodError') });
        });
      this.refreshSummary();
    },
    saveWorklogs() {
      this.creatingWorklogs = true;
      const saveWorklogCommand = {
        workedAt: toString(this.selectedDate, 'YYYY-MM-DD'),
        employeeID: this.selectedEmployee,
        startedAt: this.form.startedAt,
        endsAt: this.form.endsAt,
        remarks: this.form.remarks,
        breakDuration: this.getBreakDurationAsTimeSpan(),
        includedOrders: this.filteredItems.filter(e => this.included.some(id => id === e.orderID))
      };
      this.$store
        .dispatch('employeeWorklog/saveWorklog', saveWorklogCommand)
        .then(data => {
          this.$store.dispatch('notify/success', { message: this.$i18n.t('employeeWorklog.worklogsCreated') });
        })
        .finally(() => {
          this.creatingWorklogs = false;
          this.refreshList();
        });
    },
    getBreakDurationAsTimeSpan() {
      const hours = (this.form.breakDurationHours || '00').padStart(2, '0');
      const minutes = (this.form.breakDurationMinutes || '00').padStart(2, '0');
      return `${hours}:${minutes}`;
    },
    refreshList() {
      this.loading = true;
      this.$store
        .dispatch('employeeWorklog/getEmployeeWorklog', this.listParams)
        .then(employeeWorkLog => {
          this.costs = employeeWorkLog.costs;
          this.change = employeeWorkLog.change;
          this.form.remarks = employeeWorkLog.remarks;
          this.form.endsAt = employeeWorkLog.endsAt;
          this.form.startedAt = employeeWorkLog.startedAt;
          if (employeeWorkLog.breakDuration) {
            const parts = employeeWorkLog.breakDuration.split(':');
            this.form.breakDurationHours = parts[0];
            this.form.breakDurationMinutes = parts[1];
          } else {
            this.form.breakDurationHours = null;
            this.form.breakDurationMinutes = null;
          }
          this.filteredItems = employeeWorkLog.notScannedOrders;
          this.loadedData.items = employeeWorkLog.notScannedOrders;
          this.loadedData.total = employeeWorkLog.notScannedOrders.length;
          this.totalAmount = 0;
          this.scannedOrders = employeeWorkLog.scannedOrders;
          employeeWorkLog.notScannedOrders.forEach(o => (this.totalAmount += o.amount));
        })
        .finally(() => {
          this.loading = false;
        });
    },
    handleEmployeeChange() {
      this.refreshList();
    },
    handleWorkedAtChange() {
      this.refreshList();
    },
    getMaxTableHeight() {
      if (this.$refs.tableContainer) {
        return window.innerHeight - this.$refs.tableContainer.getBoundingClientRect().top - 40;
      }
    },
    excludeRow(row) {
      this.$refs.unassignedTicketsList.toggleRowSelection(row, false);
    },
    includeRow(row) {
      this.$refs.unassignedTicketsList.toggleRowSelection(row, true);
    },
    isIncluded(row) {
      return this.included.some(id => id === row.orderID);
    },
    canChangePaidAmount(row) {
      return row.paymentMethod === PaymentMethodOption.Pin;
    },
    onTicketNumberEntered() {
      const ticketNumber = this.form.ticketNumber;
      setTimeout(() => {
        this.form.ticketNumber = null;
        let order = this.filteredItems.find(e => e.ticketNumber === ticketNumber);
        if (!order) {
          order = this.filteredItems.find(e => {
            return e.ticketNumber.split(' ')[2] === ticketNumber;
          });
        }
        if (order) {
          this.includeRow(order);
        } else {
          this.$store.dispatch('notify/error', {
            message: this.$i18n.t('employeeWorklog.orderNotFound', { ticketNumber: ticketNumber })
          });
        }
      }, 100);
    },
    isElectronicallyPaid(order) {
      return (
        order.paymentMethod === PaymentMethodOption.Pin ||
        order.paymentMethod === PaymentMethodOption.PinAtDoorstep ||
        order.paymentMethod === PaymentMethodOption.Online ||
        order.paymentMethod === PaymentMethodOption.Internet ||
        order.paymentMethod === PaymentMethodOption.Ideal ||
        order.paymentMethod === PaymentMethodOption.Sofort ||
        order.paymentMethod === PaymentMethodOption.Giropay ||
        order.paymentMethod === PaymentMethodOption.Eckarte ||
        order.paymentMethod === PaymentMethodOption.CreditcardAtDoorstep
      );
    },
    isCash(order) {
      return order.paymentMethod === PaymentMethodOption.Cash;
    },
    isGiftCard(order) {
      return order.paymentMethod === PaymentMethodOption.Giftcard;
    },
    isInvoice(order) {
      return (
        order.paymentMethod === PaymentMethodOption.Account ||
        order.paymentMethod === PaymentMethodOption.Other ||
        order.paymentMethod === PaymentMethodOption.Unknown
      );
    },
    formatAmount(amount, sign = '') {
      return (
        '€ ' +
        (amount ?? 0).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) +
        ' ' +
        sign
      );
    },
    refreshSummary() {
      this.refreshSummaryTrigger++;
    }
  }
};
</script>

<style lang="scss">
.selectionFooterLabel {
  background-color: white;
  display: block;
  padding-bottom: 8px;
  font-size: 14px !important;
}

.el-tag--danger {
  background-color: white;
  color: #e4061f;
  border-color: #e4061f;
}
</style>
